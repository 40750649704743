.service-section {
  padding: 50px 10px;
  background-color: #ffffff;


  .service-bg {
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    padding: 80px 20px;
    padding-top: 120px;
    margin: 0;
  }

  .service-sec-container {
    text-align: center;
    color: #fff;

    .service-sec-title {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 20px;
      text-transform: uppercase;
      color: #ffffff;
    }

    p {
      font-size: 18px;
      margin-bottom: 40px;
      color: #ffffff;
    }

    .service-main-container {
      position: relative;

      .service-swiper {
        padding: 20px 0;
        max-width: 80%;
        margin: 0 auto;

        .service-swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 300px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 15px;
          position: relative;
          overflow: hidden;

          .service-slide-content {
            position: absolute;
            bottom: 20px;
            left: 20px;
            background: rgba(104, 103, 103, 0.356);
            padding: 20px;
            border-radius: 8px;
            text-align: left;

            h2 {
              font-size: 24px;
              margin-bottom: 10px;
              color: #ffffff;
            }

            p {
              font-size: 16px;
              margin-bottom: 10px;
              color: #dddddd;
            }

            .service-slider-btn {
              display: inline-block;
              padding: 10px 20px;
              background-color: #057ff8a4;
              color: #fff;
              text-decoration: none;
              border-radius: 5px;
              transition: background-color 0.3s;

              &:hover {
                background-color: #0056b3;
              }
            }
          }
        }
      }

      .swiper-pagination-bullet {
        background: #007bff;
        opacity: 1;
        transition: background 0.3s;

        &-active {
          background: #ff5722;
        }
      }
    }
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .service-sec-container {
    .service-sec-title {
      font-size: 28px;
    }

    p {
      font-size: 16px;
    }

    .service-main-container {
      .service-swiper {
        .service-swiper-slide {
          height: 300px;

          .service-slide-content {
            h2 {
              font-size: 20px;
            }

            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .service-sec-container {
    .service-sec-title {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }

    .service-main-container {
      .service-swiper {
        .service-swiper-slide {
          height: 250px;

          .service-slide-content {
            h2 {
              font-size: 18px;
            }

            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
