.hdr-container {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 20px;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.folder-headings {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.folder-heading {
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 18px;
}

.folder-heading.active,
.folder-heading:hover {
    background-color: #ccc;
    font-weight: bold;
}

.hdr-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.hdr-grid-item {
    position: relative;
    overflow: hidden;
    border: 2px solid #ddd;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.hdr-grid-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.hdr-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.hdr-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 10px;
}

.hdr-grid-item:hover .hdr-overlay {
    opacity: 1;
}

.hdr-overlay-text {
    font-size: 16px;
    font-weight: bold;
}
