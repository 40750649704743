:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
}

body {
    background-image: url('https://i2.au.reastatic.net/800x600/2c306e26925425cc64daf26e4a82bfbc191dc01b4867df2be6f7b9c87d64ea83/image.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* Keeps the background fixed during scroll */
}

.home {
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;

    .secContainer {
        .homeText {
            text-align: center;

            .title {
                color: var(--whiteColor);
                font-size: 2rem;
                font-weight: 700;
                line-height: 2.5rem;
            }

            .subTitle {
                color: var(--whiteColor);
                opacity: 0.9;
                font-size: 13px;
                font-weight: 300;
                padding: 1rem 0;
                max-width: 70%;
                text-align: center;
                margin: auto;
            }

            .btn {
                padding: 0.6rem 1.5rem;

                a {
                    color: var(--whiteColor);
                    text-decoration: none;

                    &:hover {
                        color: var(--HoverColor);
                    }
                }
            }
        }


        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            a {
                color: var(--whiteColor);
                text-decoration: none;
            }

            svg {
                color: var(--PrimaryColor)
                    /* Adjust color as needed */
            }
        }

        // .homeCard {
        //     width: 90%;
        //     max-width: 1200px;
        //     border-radius: 1rem;
        //     padding: 2rem;
        //     background: var(--whiteColor);
        //     position: absolute;
        //     gap: 1rem;
        //     justify-content: center;
        //     bottom: -10%;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     box-shadow: 0 2px 8px 4px rgba(178, 178, 178, 0.45);
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;

        //     @media (min-width: 768px) {
        //         bottom: -10%;
        //         padding: 1rem;
        //         gap: 1rem;
        //         width: 80%;
        //         flex-direction: row;
        //     }

        //     .nameDiv, .numberDiv, .emailDiv {
        //         width: 100%;
        //         display: flex;
        //         flex-direction: column;
        //         margin-bottom: 1rem;

        //         @media (min-width: 768px) {
        //             width: 30%;
        //             margin-bottom: 0;
        //         }

        //         label {
        //             display: block;
        //             color: var(--textColor);
        //             font-weight: 500;
        //             font-size: 13px;
        //             padding-bottom: 0.4rem;
        //         }

        //         input {
        //             width: 100%;
        //             background: var(--inputColor);
        //             border-radius: 10px;
        //             border: none;
        //             padding: 10px;
        //             font-size: 13px;
        //             font-weight: 500;

        //             &::placeholder {
        //                 font-size: 13px;
        //                 opacity: 0.5;
        //                 font-weight: 400;
        //             }

        //             &:focus {
        //                 outline: none;
        //             }
        //         }
        //     }

        //     .btn {
        //         width: 100%;
        //         padding: 0.6rem 1.5rem;
        //         color: var(--whiteColor);
        //         font-weight: 600;
        //         background-color: var(--PrimaryColor);
        //         border: none;
        //         border-radius: 10px;
        //         text-align: center;
        //         cursor: pointer;

        //         a {
        //             color: var(--whiteColor);
        //             text-decoration: none;

        //             &:hover {
        //                 color: var(--HoverColor);
        //             }
        //         }

        //         @media (min-width: 768px) {
        //             width: auto;
        //         }
        //     }
        // }
    }
}