.contactUs {
  .secContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f8f8f9; /* Light background color */
    padding: 2rem;
    border-radius: 10px;

    .secIntro, .contactInfo {
      flex: 1;
      min-width: 300px;
      margin-bottom: 2rem;
    }

    .secIntro {
      margin-right: 2rem; /* Add space between the intro and info */
      
      .secTitle {
        font-size: 2rem; /* Slightly smaller for better mobile fit */
        color: #c91c1c;
        margin-bottom: 1rem;
        text-align: center;
      }

      p {
        font-size: 1rem;
        color: #e20707;
        text-align: center;
        margin-bottom: 1.5rem;
      }

      form {
        .formGroup {
          margin-bottom: 1rem;

          label {
            display: block;
            font-size: 1rem;
            margin-bottom: 0.5rem;
            color: #111439;
          }

          input[type='text'],
          input[type='email'],
          textarea {
            width: 100%;
            padding: 0.75rem;
            border-radius: 5px;
            border: 1px solid #aaa;
            font-size: 1rem;
            color: #333;
            background-color: #e5edf1;
          }

          textarea {
            resize: vertical;
          }
        }

        button[type='submit'] {
          background-color: #111439;
          color: #fff;
          padding: 0.75rem 2rem;
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
          display: block;
          width: 100%;
          max-width: 200px;
          margin: 0 auto;

          &:hover {
            background-color: #002349;
          }
        }
      }
    }

    .contactInfo {
      background-color: #111439;
      color: #fff;
      padding: 2rem;
      border-radius: 10px;
      text-align: center;

      .contactHeader {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        color: #bfa181;
      }

      .socialIcons {
        margin-bottom: 1rem;

        a {
          color: #fff;
          font-size: 1.5rem;
          margin: 0 0.5rem;
          transition: color 0.3s ease;

          &:hover {
            color: #bfa181;
          }
        }
      }

      p {
        margin: 1rem 0;
        font-size: 1.1rem;
        line-height: 1.6;
      }

      b {
        color: #bfa181;
      }
    }
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .contactUs .secContainer {
    flex-direction: column;
  }

  .contactUs .secIntro,
  .contactUs .contactInfo {
    margin-right: 0;
    margin-bottom: 2rem;
    max-width: 100%;
    padding: 0 1rem;
  }

  .contactUs .secIntro .secTitle {
    font-size: 1.8rem;
  }

  .contactUs .secIntro p,
  .contactUs .contactInfo p {
    font-size: 0.9rem;
  }

  .contactUs .contactInfo {
    padding: 1.5rem;
  }
}
