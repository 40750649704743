@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    transition: .3s ease;
}

/* Colour */

:root {

    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor:  hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    /* box-shadow: 0 2px 8px rgba(178,178,178,0.334); */
}


a{
    text-decoration: none;
}


li{
    list-style: none;
}
.section{
    padding: 4rem 0 2rem;
}

.container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.icon{
    font-size: 2rem;
    cursor: pointer;
}
.flex{
    display: flex;
    align-items: center;
}
.grid{
    display: flex;
    align-items: center;
}
.btn{
    padding: .4rem 1.5rem;
    background: var(--PrimaryColor);
    border: none;
    outline: none;
    border-radius: 3rem;
    cursor: pointer;
}

.btn a{
    color : var(--whiteColor);
    font-weight: 500;
}

.btn:hover a{
    color: var(--whiteColor);
}

.secIntro{
    max-width: 70%;
    text-align: center;
    margin: 0 auto 2 rem;
}

.secIntro .secTitle{
    color: var(--blackColor);
    line-height: 1.5rem;
    padding-bottom: .5rem;
}


.secIntro p{
    font-size: 13px;
    color: var(--textColor);
    font-weight: 500;
}

img, video{
    width: 100%;
    height: auto;
}

input{
    font-size: 100%;
}