:root {
  --PrimaryColor: #E56717; /* Denim Blue */
  --HoverColor: hsl(26, 90%, 42%); /* Citrus Orange */
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --CitrusOrangeBg: #E56717; /* Citrus Orange */
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
}



.aboutUs {
.title {
  font-size: 2rem;
  font-weight: bold;
  padding: 10px;
  background-color: var(--greyBg); /* Use grey background for the About Us heading */
  text-align: center;
  color: var(--CitrusOrangeBg); /* Text color */
}

.teamContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media(min-width: 768px) {
    flex-direction: row;
  }
}

.memberImage {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(227, 255, 255, 0.877); /* White background for the card */
  padding: 20px;
  border-radius: 12px; /* Rounded corners for the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for the card */
  margin-bottom: 20px;
  text-align: center;

  img {
    width: 150px; /* Medium size photo */
    height: auto;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 8px; /* Slightly rounded corners for the image */
  }

  .memberName {
    margin-top: 10px;
    color: var(--HoverColor); /* Text color */
    font-size: 1.2rem;
    font-weight: bold;
  }

  @media(min-width: 768px) {
    flex: 0 0 40%;
    margin-bottom: 0;
  }
}

.memberDescription {
  flex: 0 0 100%;
  padding: 10px;
  text-align: center;
  color: var(--blackColor); /* Text color */

  .teamTitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--PrimaryColor); /* Primary color for title */
  }

  .description {
    font-size: 1rem;
    line-height: 1.5;
    background-color: rgba(227, 255, 255, 0.877);
  }

  @media(min-width: 768px) {
    flex: 0 0 55%;
    text-align: left;
  }
}

.mission {
  margin-top: 30px;

  .missionTitle {
    font-size: 1.5rem;
    background-color: var(--greyBg); /* Background color for the Mission heading */
    padding: 5px 10px;
    text-align: center;
    color: var(--blackColor); /* Text color */
  }

  p {
    margin-top: 10px;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    color: var(--CitrusOrangeBg); /* Text color */

    @media(min-width: 768px) {
      text-align: left;
    }
  }
}
}
